<template>
  <div class="map-main">
    <div class="map-main-left">
      <!-- 中国地图 -->
      <china-map
        v-if="tab=='china'"
        ref="chinaMap"
        :xiaoniao="xiaoniao"
        :chinaMapData="chinaMapData"
        class="china-map"
      ></china-map>
      <!-- 世界地图 -->
      <world-map
        v-if="tab=='world'"
        ref="worldMap"
        :xiaoniao="xiaoniao"
        :worldMapData="worldMapData"
        class="world-map"
      ></world-map>
      <div v-if="tab=='world'" class="world-tips">{{websiteTotal}} 个独立站覆盖全球 {{visitCountryTotal}} 个国家和地区</div>
    </div>
    <div class="map-main-right">
      <div class="map-tab">
        <div
          :class="{'tab-item': true, 'tab-activate': tab=='china'}"
          @click="changeTab('china')"
        >国内访客</div>
        <div
          :class="{'tab-item': true, 'tab-activate': tab=='world'}"
          @click="changeTab('world')"
        >全球访客</div>
      </div>
      <div class="ranking-table">
        <div class="ranking-table-header">
          <div class="th th1">访客排行</div>
          <div class="th th2">地区名称</div>
          <!-- <div class="th th3">访客数</div> -->
        </div>
        <div
        class="ranking-table-line"
        v-for="(item, i) in currentRankingList"
        :key="item.region_name"
        >
        <div class="td td1">
            <template v-if="i<3">
            <img :src="require('@/assets/images/top_'+(i+1)+'.png')">
            </template>
            <template v-else>
            TOP{{i+1}}
            </template>
        </div>
        <div class="td td2">{{item.region_name}}</div>
        <!-- <div class="td td3">{{item.num}}</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChinaMap from "./china-map.vue";
import WorldMap from "./world-map.vue";
import { getListAccessBy7Day } from "@/api/index.js";
export default {
  components: {
    ChinaMap,
    WorldMap,
  },
  data() {
    return {
      tab: 'world',    //值为china和world
      xiaoniao: [116.785396, 23.452272],    //霄鸟坐标
      chinaRankingList: [], //国内排行榜数据，[{ region_name: '广东省', num: 10 },{ region_name: '广东省', num: 10 }]
      worldRankingList: [], //全球排行榜数据
      chinaMapData: [], //国内地图数据 [{ region_name: '广东省', num: 10 },{ region_name: '广东省', num: 10 }]
      worldMapData: [], //全球地图数据
      currentRankingList: [],   //当前的排行榜
      currentMapData: [],  //当前的区域数据
      websiteTotal: 0, // 网站数
      visitCountryTotal: 0 //访问国家数
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.changeTab(this.tab);
    })
    this.loadData();
  },
  methods: {
    //加载数据
    loadData() {
      getListAccessBy7Day({ type: 0 }).then(res => {
        if (res.code == 0) {
          this.chinaRankingList = res.data.data7d.slice(0, 10);
          this.chinaMapData = res.data.dataAll;
          if (this.tab == 'china') this.changeTab(this.tab);
        }
      });
      getListAccessBy7Day({ type: 1 }).then(res => {
        if (res.code == 0) {
          this.worldRankingList = res.data.data7d.slice(0, 10);
          this.worldMapData = res.data.dataAll;
          if (this.tab == 'world') this.changeTab(this.tab);
          this.$emit('updateCountryTotal', this.worldMapData.length); //更新国家数
          this.visitCountryTotal = this.worldMapData.length
        }
      });
    },
    changeTab(key) {
      switch (key) {
        case 'china':
          this.tab = 'china';
          this.currentRankingList = this.chinaRankingList;
          this.$nextTick(() => {
            this.$refs.chinaMap.initChinaMap();
          });
          break;
        case 'world':
          this.tab = 'world';
          this.currentRankingList = this.worldRankingList;
          this.$nextTick(() => {
            this.$refs.worldMap.initWorldMap();
          });
          break;
      }
    },
    setWebsiteNum(websiteTotal) {
      this.websiteTotal = websiteTotal;
    }
  }
};
</script>
<style lang="scss" scoped>
.map-main {
  display: flex;
  height: calc(100% - 42px);
  .map-main-left {
    position: relative;
    width: 861px;
    .china-map {
      height: 100%;
      width: 100%;
    }
    .world-map {
      height: 100%;
      width: 100%;
    }
    .world-tips{
      width: 100%;
      position: absolute;
      bottom: 8px;
      // left: 32%;
      color: #fff;
      font-size: 16px;
      text-align: center;
    }
  }
  .map-main-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    box-sizing: border-box;
    padding-right: 40px;
    .map-tab {
      display: flex;
      margin-top: 17px;
      .tab-item {
        font-size: 16px;
        font-weight: 400;
        color: #cee9ff;
        height: 31px;
        margin-left: 35px;
        cursor: pointer;
      }
      .tab-activate {
        background: url(~@/assets/images/tab_activate_bg.png) bottom;
        background-size: 100% 100%;
        color: #45b2ff;
      }
    }
    .ranking-table {
      margin-top: 15px;
      width: 317px;
      height: 290px;
      background: rgba(0, 45, 79, 0);
      border: 0px solid #a4f9ff;
      box-shadow: inset 0px 3px 13px 0px rgba(90, 225, 254, 0.41);
      .ranking-table-header {
        height: 35px;
        background: #002e59;
        opacity: 0.85;
        display: flex;
        align-items: center;
      }
      .ranking-table-line {
        width: 317px;
        height: 24px;
        opacity: 0.85;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #172338;
        &:last-child {
          border-bottom: 0;
        }
      }
      .th {
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        text-align: center;
      }
      .td {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .th1,
      .td1 {
        width: 152px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 14px;
          width: 33px;
          object-fit: contain;
        }
      }
      .td1{
        font-style: oblique;
        font-size: 13px;
        color: #CECECE;
        line-height: 28px;
        text-align: center;
        text-transform: none;
      }
      .th2,
      .td2 {
        flex: 1;
      }
      .th3,
      .td3 {
        width: 88px;
        flex-shrink: 0;
      }
    }
  }
}
</style>