<template>
  <div class="ranking-main-container">
    <div class="container-title">
      <div>排行区</div>
      <div>
        <custom-select
          v-model="select"
          :option="option"
          @change="selectChange"
        ></custom-select>
      </div>
    </div>
    <div class="ranking-main-container-main">
      <div class="ranking-block">
        <div class="ranking-block-title ranking-block-title1"></div>
        <div class="ranking-block-content">
          <ranking-keyword
            ref="rankingKeyword"
            :list="rankingKeywordList"
          ></ranking-keyword>
        </div>
      </div>
      <div class="ranking-block">
        <div class="ranking-block-title ranking-block-title2"></div>
        <div class="ranking-block-content">
          <ranking-category
            ref="rankingCategory"
            :list="rankingCategoryList"
          ></ranking-category>
        </div>
      </div>
      <div class="ranking-block">
        <div class="ranking-block-title ranking-block-title3"></div>
        <div class="ranking-block-content">
          <ranking-factory
            ref="rankingFactory"
            :list="rankingFactoryList"
          ></ranking-factory>
        </div>
      </div>
      <div class="ranking-block">
        <div class="ranking-block-title ranking-block-title4"></div>
        <div class="ranking-block-content">
          <ranking-toy
            ref="rankingToy"
            :list="rankingToyList"
          ></ranking-toy>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from "./select.vue";
import RankingKeyword from "./ranking-keyword.vue";
import RankingCategory from "./ranking-category.vue";
import RankingFactory from "./ranking-factory.vue";
import RankingToy from "./ranking-toy2.vue";
import { getListKeyword, getListCategory, getListFactory, getListToy } from "@/api/index.js";
export default {
  components: {
    CustomSelect,
    RankingKeyword,
    RankingCategory,
    RankingFactory,
    RankingToy,
  },
  data() {
    return {
      option: [{ value: 0, label: '近一周' }, { value: 1, label: '近一个月' }, { value: 2, label: '近一年' }],
      select: 0,
      rankingKeywordList: [],
      rankingCategoryList: [],
      rankingFactoryList: [],
      rankingToyList: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    selectChange() {
      this.loadData();
    },
    loadData() {
      getListCategory({ type: this.select }).then(res => {
        if (res.code == 0) {
          this.rankingCategoryList = res.data;
          this.$nextTick(() => {
            this.$refs.rankingCategory.initData();
          })
        }
      });
      getListFactory({ type: this.select }).then(res => {
        if (res.code == 0) {
          this.rankingFactoryList = res.data;
          this.$nextTick(() => {
            this.$refs.rankingFactory.initData();
          })
        }
      });
      getListToy({ type: this.select }).then(res => {
        if (res.code == 0) {
          this.rankingToyList = res.data;
          this.$nextTick(() => {
            this.$refs.rankingToy.initData();
          })
        }
      });
      getListKeyword({ type: this.select }).then(res => {
        if (res.code == 0) {
          this.rankingKeywordList = res.data;
          this.$nextTick(() => {
            this.$refs.rankingKeyword.initData();
          })
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.ranking-main {
  width: 100%;
  height: 100%;
  .container-title {
    height: 39px;
    margin-left: 24px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
  }
  .ranking-main-container-main {
    display: flex;
    flex-flow: wrap;
    .ranking-block {
      width: 50%;
      height: 330px;
      display: flex;
      flex-direction: column;
      .ranking-block-title {
        width: 141px;
        height: 27px;
        margin-top: 16px;
        flex-shrink: 0;
      }
      .ranking-block-title1 {
        background: url(~@/assets/images/ranking_title_1.png) no-repeat;
        background-size: 100% 100%;
      }
      .ranking-block-title2 {
        background: url(~@/assets/images/ranking_title_2.png) no-repeat;
        background-size: 100% 100%;
      }
      .ranking-block-title3 {
        background: url(~@/assets/images/ranking_title_3.png) no-repeat;
        background-size: 100% 100%;
      }
      .ranking-block-title4 {
        background: url(~@/assets/images/ranking_title_4.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    .ranking-block-content {
      flex: 1;
      margin-left: 15px;
      // margin-right: 40px;
    }
  }
}
</style>